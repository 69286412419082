@use 'src/styles/colors';


.container {
  cursor: pointer;
  user-select: none;
}

.iconBox {
  width: 22px;
  height: 22px;
  text-align: center;

  border-radius: var(--base-b-radius);
  border-width: 1px;
  border-style: solid;

  border-color: colors.$base-secondary-2;

  &Selected {
    background-color: colors.$brand-primary;
    border-color: colors.$brand-primary;
  }

  &Disabled {
    background-color: colors.$base-secondary-pale !important;
    border-color: colors.$base-secondary-2 !important;
  }

  &:hover,
  :hover > & {
    border-color: colors.$brand-primary;
  }

  &:active,
  :active > & {
    box-shadow: 0 0 0 2px colors.$brand-secondary-pale;
  }

  &Disabled:active,
  :active > &Disabled {
    box-shadow: none;
  }


  &Selected:hover,
  :hover > &Selected {
    background-color: colors.$brand-additional-dark;
    border-color: colors.$brand-additional-dark;
  }
}

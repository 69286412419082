@use 'src/styles/colors';


.container {
  z-index: 1060;
  background-color: var(--base-color-white);
  width: 288px;

  &Standalone {
    box-shadow: 0 8px 16px 4px #2000541F;
    border-radius: var(--base-b-radius);
  }

}

.blockDivider {
  &Bottom {
    border-bottom: 1px solid var(--brand-secondary-pale);
  }
  &Top {
    border-top: 1px solid var(--brand-secondary-pale);
  }
}


.daysContainer {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.dayButton {
  width: 24px;
  height: 24px;

  border-radius: var(--base-b-radius);

  border: 1px solid transparent;

  &:hover {
    border: 1px solid var(--brand-primary);
  }

  &Selected {
    background-color: var(--brand-primary);
  }

  &InRange {
    background-color: colors.$brand-secondary-pale;

    &Start {
      border-radius: var(--base-b-radius) 0 0 var(--base-b-radius);
    }

    &End {
      border-radius: 0 var(--base-b-radius) var(--base-b-radius) 0;
    }
  }


  &Disabled {
    cursor: default;
    border: 1px solid transparent !important;
    background-color: transparent !important;
  }
}
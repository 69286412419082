@import 'src/styles/bootstrapVars';
@import 'bootstrap/scss/mixins/breakpoints';

.overlay {
  background-color: rgba(black, 0.2);

  z-index: 1055;
  display: flex;
  width: 100vw;
  height: 100dvh;
  padding: 18px 14px;
  justify-content: center;

  @include media-breakpoint-up(md) {
    &MediumPadging {
      padding-top: 100px;
    }
  }
}

.content {
  z-index: 1056;
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  max-height: 95dvh;
  overflow: auto;

  padding: 24px;
  background-color: var(--base-color-white);
  border-radius: var(--base-b-radius);
  position: relative;

  align-self: center;

  &LeftAligned {
    height: 100%;
  }


  @include media-breakpoint-up(md) {
    &LeftAligned {
      max-height: 100dvh;
      width: 50.78%;
    }
  }

  @include media-breakpoint-up(lg) {
    &LeftAligned {
      width: 36.11%;
    }
  }

  @include media-breakpoint-up(xl) {
    &LeftAligned {
      width: 27.08%;
    }
  }
}

.closeBtn {
  z-index: 1057;
  position: absolute;
  top: 2px;
  right: 4px;
  color: var(--base-secondary-1);
}

.hr {
  width: 100%;
  height: 1px;
  background-color: var(--base-secondary-pale);
  border-radius: var(--base-b-radius);

  &First {
    display: none;
  }

  @include media-breakpoint-up(md) {
    &First {
      display: block;
    }
  }
}


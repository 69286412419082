

.container {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.antInput {
  height: 34px;
  border-color: var(--base-secondary-2);

  border-radius: var(--base-b-radius);
}

$grid-breakpoints: (
        xs: 0,
        sm: 370px,
        md: 1020px,
        lg: 1430px,
        xl: 1900px,
);

$spacers: (
        0: 0,
        2: 2px,
        4: 4px,
        6: 6px,
        8: 8px,
        12: 12px,
        14: 14px,
        16: 16px,
        24: 24px,
        32: 32px,
        48: 48px,
);

$utilities: (
        "width": (
                responsive: true,
                property: width,
                class: w,
                values: (
                        25: 25%,
                        50: 50%,
                        75: 75%,
                        100: 100%,
                        auto: auto
                )
        )
);

$paragraph-margin-bottom: 0;

@use 'sass:map';
@use "bootstrap";
@use "fonts";
@use "vars";
@use "colors";
@use 'common';

:root {
  @include colors.colorVariables;

  --base-b-radius: 4px;
}

body {
  white-space: pre-line;
}


.hr {
  width: 100%;
  height: 1px;
  background-color: colors.$brand-secondary-pale;
}

.develop {
  background-repeat: repeat;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZlcnNpb249JzEuMScgaGVpZ2h0PSc2MHB4JyB3aWR0aD0nMTIwcHgnPjx0ZXh0IHg9JzEyJyB5PScxNScgb3BhY2l0eT0nMC4yJyB0cmFuc2Zvcm09J3JvdGF0ZSg0NSAwIDApJyBmaWxsPScwMDAwZmYnIGZvbnQtc2l6ZT0nMjAnPkluIGRldiE8L3RleHQ+PC9zdmc+");
}

* {
  ::-webkit-scrollbar{
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: colors.$base-secondary-pale;
    border-radius: 8px;
  }

  //outline: 1px dashed var(--green-secondary-2);
}

#icon-wrapper {
  line-height: 1px !important;
}

@import 'src/styles/bootstrapVars';
@import 'bootstrap/scss/mixins/breakpoints';

@include media-breakpoint-up(md) {
  .content {
    width: 52.2%;
  }
}

@include media-breakpoint-up(lg) {
  .content {
    width: 36.82%;
  }
}

@include media-breakpoint-up(xl) {
  .content {
    width: 27.48%;
  }
}


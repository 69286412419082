
.container {
  min-height: 1rem;

  background: linear-gradient(
                  89.91deg,
                  #F0F0F0 40%,
                  rgba(240, 240, 240, 0.03),
                  #F0F0F0 60%
  ) right/300% 100%;
  animation: backgroungShift 3s linear infinite;

  &string {
    border-radius: 2px;
  }

  &circle {
    border-radius: 50%;
    aspect-ratio: 1;
  }

  &square {
    border-radius: 2px;
    aspect-ratio: 1;
  }

}

@keyframes backgroungShift {
  to {background-position:left;}
}
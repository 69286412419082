@use 'src/styles/colors';
@import 'src/styles/bootstrapVars';
@import 'bootstrap/scss/mixins/breakpoints';

.list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

@include media-breakpoint-up(md) {
  .list {
    grid-template-columns: repeat(3, 1fr);
  }
}

.fullWidthCard {
  user-select: none;
  border-radius: var(--base-b-radius);

  border: 1px solid colors.$base-secondary-pale;

  color: colors.$base-secondary-1;

  &:hover {
    border-color: colors.$brand-secondary-2;
    background-color: colors.$brand-secondary-pale;
  }

  &Icon {
    :hover > & {
      color: colors.$brand-primary;
    }

    :focus-visible, :active > & {
      color: colors.$brand-additional-dark;
    }
  }

  &Name {
    :focus-visible, :active > * > & {
      color: colors.$brand-additional-dark;
    }
  }
}

.logo48 {
  width: 48px;
  height: 48px;

  &Rounded {
    border-radius: 50%;
  }
}
@use 'src/styles/colors';

.icon {
  border-radius: 50%;

  background-color: transparent;

  --outer-circle: #{colors.$base-secondary-2};
  --inner-circle: #{colors.$brand-primary};

  &:hover,
  *:hover > & {
    --outer-circle: #{colors.$brand-primary};
    --inner-circle: #{colors.$brand-primary};
  }

  &:focus-visible, &:active {
    --outer-circle: #{colors.$brand-additional-dark};
    --inner-circle: #{colors.$brand-additional-dark};
  }


  &Disabled {
    background-color: colors.$base-secondary-pale !important;

    --outer-circle: #{colors.$base-secondary-2} !important;
    --inner-circle: #{colors.$base-secondary-2} !important;
  }
}


.base {
  text-decoration: none;

  &:hover {
    color: var(--brand-additional-dark);
  }

  &:active {
    color: var(--brand-additional-dark) !important;
  }
}


.tooltipContainer {
  z-index: 1070;
}

.tooltipContent {
  background-color: var(--base-primary-dark);
  border-radius: var(--base-b-radius);
  z-index: 1070;
  padding: 6px 8px;
}
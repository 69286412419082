@use 'src/styles/colors';


.label {
  border-radius: var(--base-b-radius);
  border-width: 1px;
  border-style: solid;


  &default {
    background-color: colors.$base-secondary-pale;
    border-color: transparent;
    color: colors.$base-primary-dark;
  }

  &error {
    background-color: colors.$red-secondary-pale;
    border-color: colors.$red-primary;
    color: colors.$red-primary;
  }

  &info {
    background-color: colors.$brand-secondary-pale;
    border-color: colors.$brand-secondary-2;
    color: colors.$brand-primary;
  }

  &success {
    background-color: colors.$green-secondary-pale;
    border-color: colors.$green-secondary-2;
    color: colors.$green-primary;
  }

  &warn {
    background-color: colors.$orange-secondary-pale;
    border-color: colors.$orange-secondary-2;
    color: colors.$orange-primary;
  }
}
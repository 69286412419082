@import 'src/styles/bootstrapVars';
@import 'bootstrap/scss/mixins/breakpoints';

.listContainer {
  gap: 16px;
  //padding-bottom: 48px;
}

.bannerContainer {
  padding: 16px;
  gap: 8px;

  background-color: var(--brand-secondary-pale);
  border-radius: var(--base-b-radius);
}

.closeIcon {
  top: 4px;
  right: 6px;

  color: var(--base-secondary-1);
}

@include media-breakpoint-up(lg)  {
  .listContainer {
    margin-top: 38px;
  }
}
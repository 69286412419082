@use 'src/styles/colors';

.contentTable {
  grid-template-columns: 3fr 7fr 3fr;

  border: 1px solid colors.$brand-secondary-pale;
  border-radius: var(--base-b-radius);

  row-gap: 1px;
  column-gap: 1px;

  background-color: colors.$brand-secondary-pale;

  > * {
    background-color: white;
  }

  > *:nth-child(1) {
    border-top-left-radius: 3px;
  }
  > *:nth-child(3) {
    border-top-right-radius: 3px;
  }
  > *:nth-last-child(3) {
    border-bottom-left-radius: 3px;
  }
  > *:nth-last-child(1) {
    border-bottom-right-radius: 3px;
  }

  &HeaderRow {
    grid-template-columns: 3fr 7fr 3fr;
  }
}

@use "sass:map";

$types: (
        Primary: (
                default: (
                        textColor: var(--base-color-white),
                        backgroungColor: var(--brand-primary),
                        borderColor: var(--brand-primary),
                        outline: none,
                ),
                hover: (
                        textColor: var(--base-color-white),
                        backgroungColor: var(--brand-additional-dark),
                        borderColor: var(--brand-additional-dark),
                        outline: none,
                ),
                focus: (
                        textColor: var(--brand-secondary-1),
                        backgroungColor: var(--brand-additional-dark),
                        borderColor: var(--brand-additional-dark),
                        outline: none,
                ),
                disabled: (
                        textColor: var(--base-secondary-2),
                        backgroungColor: var(--base-secondary-pale),
                        borderColor: var(--base-secondary-pale),
                        outline: none,
                ),
        ),
        Secondary: (
                default: (
                        textColor: var(--brand-primary),
                        backgroungColor: transparent,
                        borderColor: var(--base-secondary-2),
                        outline: none,
                ),
                hover: (
                        textColor: var(--brand-primary),
                        backgroungColor: var(--brand-secondary-pale),
                        borderColor: var(--brand-primary),
                        outline: none,
                ),
                focus: (
                        textColor: var(--brand-additional-dark),
                        backgroungColor: var(--brand-secondary-pale),
                        borderColor: var(--brand-additional-dark),
                        outline: 2px solid var(--brand-secondary-pale),
                ),
                disabled: (
                        textColor: var(--base-secondary-2),
                        backgroungColor: transparent,
                        borderColor: var(--base-secondary-pale),
                        outline: none,
                ),
        ),
        Danger: (
                default: (
                        textColor: var(--red-primary),
                        backgroungColor: transparent,
                        borderColor: var(--red-primary),
                        outline: none,
                ),
                hover: (
                        textColor: var(--base-color-white),
                        backgroungColor: var(--red-secondary-1),
                        borderColor: var(--red-secondary-1),
                        outline: none,
                ),
                focus: (
                        textColor: var(--base-color-white),
                        backgroungColor: var(--red-additional-dark),
                        borderColor: var(--red-additional-dark),
                        outline: 1px solid var(--red-secondary-pale),
                ),
                disabled: (
                        textColor: var(--base-secondary-2),
                        backgroungColor: transparent,
                        borderColor: var(--base-secondary-pale),
                        outline: none,
                ),
        ),
        TextButton: (
                default: (
                        textColor: var(--brand-primary),
                        backgroungColor: transparent,
                        borderColor: transparent,
                        outline: none,
                ),
                hover: (
                        textColor: var(--brand-additional-dark),
                        backgroungColor: transparent,
                        borderColor: transparent,
                        outline: transparent,
                ),
                focus: (
                        textColor: var(--brand-additional-dark),
                        backgroungColor: transparent,
                        borderColor: transparent,
                        outline: none,
                ),
                disabled: (
                        textColor: var(--base-secondary-2),
                        backgroungColor: transparent,
                        borderColor: transparent,
                        outline: none,
                ),
        ),
);

@mixin colorizeButton($colorMap, $flag: null) {
  color: map.get($colorMap, 'textColor') $flag;
  background-color: map.get($colorMap, 'backgroungColor') $flag;
  border-color: map.get($colorMap, 'borderColor') $flag;
  outline: map.get($colorMap, 'outline') $flag;
}

@each $name, $value in $types {
  $default: map.get($value, 'default');
  $hover: map.get($value, 'hover');
  $focus: map.get($value, 'focus');
  $disabled: map.get($value, 'disabled');

  .#{$name}Type {
    @include colorizeButton($default);

    &:active {
      @include colorizeButton($focus, !important);
    }
    &:focus-visible {
      @include colorizeButton($hover);
    }
    &:hover {
      @include colorizeButton($hover);
    }
    &:disabled {
      @include colorizeButton($disabled, !important);
    }
  }
}



.base {
  border-radius: var(--base-b-radius);
  outline: none;
  gap: 4px;
  padding: 5px 16px;

  border-width: 1px;
  border-style: solid;

  &TextButton {
    border-width: 0;
    padding: 2px 0;
  }
  &IconOnly {
    padding: 10px;
    //display: inline-flex !important;
  }
}

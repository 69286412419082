@use 'sass:map';
@use 'sass:math';
@import 'src/styles/bootstrapVars';
@import 'bootstrap/scss/mixins/breakpoints';


$widths: (
        0,
        25,
        50,
        100
);

@each $bpName, $bpValue in $grid-breakpoints {
  @include media-breakpoint-up($bpName) {
    @each $i in $widths {
      @if ($bpName == xs) {
        .cw-#{$i} {
          width: $i * 1%;
        }
      } @else {
        .cw-#{$bpName}-#{$i} {
          width: $i * 1%;
        }
      }
    }
  }
}

.scrollbar-stable {
  scrollbar-gutter: stable !important;
}

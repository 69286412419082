@use 'src/styles/colors';

.container {
  border: 1px solid colors.$base-secondary-pale;
  border-radius: 8px;
}

.buttonsRow {
  gap: 1px;
  border: 1px solid colors.$base-secondary-2;
  background-color: colors.$base-secondary-2;

  border-radius: var(--base-b-radius);
}

.percentageBtn {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0 !important;
  border: none !important;
  &Bg {
    background-color: colors.$base-color-white;
  }
}
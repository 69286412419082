@use 'src/styles/colors';

.content {
  border-radius: var(--base-b-radius);

  border-width: 1px;
  border-style: solid;
  border-color: var(--base-secondary-2);
  padding: 5px 11px;

  color: var(--base-primary-dark);

  transition: all 200ms ease-in-out;

  &:hover {
    border-color: var(--base-secondary-1);
  }

  &Open {
    border-color: var(--brand-primary);
    box-shadow: 0 0 0 2px var(--brand-secondary-pale);

    &:hover {
      border-color: var(--brand-primary);
      box-shadow: 0 0 0 2px var(--brand-secondary-pale);
    }
  }

  &Disabled {
    background-color: var(--base-secondary-pale);
    border-color: var(--base-secondary-2);

    &:hover {
      border-color: var(--base-secondary-2);
    }
  }
}

.searchInput {
  background-color: transparent;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.dropContainer {
  //bottom: -4px;
  z-index: 1060;
  overflow: auto;
  border-radius: var(--base-b-radius);
  //transform: translateY(100%);

  background-color: var(--base-color-white);

  max-height: 190px;


  box-shadow: 0 9px 28px 8px #0000000D,
  0 6px 16px 0 #00000014,
  0 3px 6px -4px #0000001F;
}

.dropItem {
  padding: 8px 12px;
  color: var(--base-primary-dark);

  &Selected {
    color: var(--brand-additional-dark);
    background-color: var(--brand-secondary-pale);
  }

  &Disabled {
    background-color: colors.$base-secondary-pale;
    color: colors.$base-secondary-1;

    &:hover {
      background-color: colors.$base-secondary-pale !important;
      color: colors.$base-secondary-1 !important;
    }
  }


  &:hover {
    background-color: var(--brand-secondary-pale);
    color: var(--brand-primary);
  }

  &Check {
    color: var(--brand-primary);
  }
}

.selectedMultipleContainer {
  max-height: 22px;
}


.displayInput {
  border: none;
  outline: none;
  padding: 0;
}

.dropContainer {
  z-index: 1060;
}

// TODO: подумать над универсальным инпутом
.inputContainer {
  cursor: text;

  border-radius: var(--base-b-radius);

  border-width: 1px;
  border-style: solid;
  border-color: var(--base-secondary-2);
  color: var(--base-secondary-2);
  padding: 5px 11px;

  transition: all 200ms ease-in-out;

  &:hover {
    border-color: var(--base-secondary-1);
    color: var(--base-secondary-1);
  }

  &Open {
    border-color: var(--brand-primary);
    color: var(--brand-primary);
    box-shadow: 0 0 0 2px var(--brand-secondary-pale);

    &:hover {
      border-color: var(--brand-primary);
      color: var(--brand-primary);
      box-shadow: 0 0 0 2px var(--brand-secondary-pale);
    }
  }

  &Disabled {
    background-color: var(--base-secondary-pale);
    border-color: var(--base-secondary-2);
    color: var(--base-secondary-2);

    &:hover {
      border-color: var(--base-secondary-2);
      color: var(--base-secondary-2);
    }
  }
}
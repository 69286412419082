@use 'src/styles/colors';
@import 'src/styles/bootstrapVars';
@import 'bootstrap/scss/mixins/breakpoints';


.imgBanner {
  background-color: colors.$brand-secondary-pale;
}

.banner {
  width: 46.26%;
}

.overlay {
  backdrop-filter: blur(4px);
}

@include media-breakpoint-up(md) {
  .banner {
    width: 49.03%;
  }
}


@use 'src/styles/colors';


.container {
  cursor: pointer;
  user-select: none;
  width: 100%;

  border-width: 1px;
  border-radius: var(--base-b-radius);

  transition: border 200ms;

  &Disabled {
    border-style: dashed;
    border-color: colors.$base-secondary-2;

    &Icon, &Title {
      color: colors.$base-secondary-2;
    }
  }

  &0 {
    border-style: dashed;
    border-color: colors.$base-secondary-2;

    &:hover, &ThisHover {
      border-style: solid;
      border-color: colors.$brand-primary;
      background-color: colors.$brand-secondary-pale;
    }

    &Icon {
      color: colors.$brand-primary;
    }

    &Title {
      color: colors.$base-primary-dark;

      :hover > * > &,
      .container0ThisHover > * > & {
        color: colors.$brand-primary;
      }

      :focus-visible > * > &,
      :active > * > & {
        color: colors.$brand-additional-dark;
      }
    }

    &:focus-visible, &:active {
      border-color: colors.$brand-additional-dark;
    }
  }

  &1 {
    border-style: solid;
    border-color: colors.$brand-secondary-2;
    background-color: colors.$brand-secondary-pale;

    &Icon, &Title {
      color: colors.$brand-primary;
    }
  }

  &2 {
    border-style: solid;
    border-color: colors.$green-primary;
    background-color: colors.$base-color-white;

    &Icon {
      color: colors.$green-primary;
    }

    &Title {
      color: colors.$base-primary-dark;
    }
  }

  &3 {
    border-style: solid;
    border-color: colors.$red-primary;

    &:hover, &ThisHover {
      border-color: colors.$red-primary;
      background-color: colors.$red-secondary-pale;
    }

    &Icon {
      color: colors.$red-primary;
    }

    &Title {
      color: colors.$base-primary-dark;

      :hover > * > &,
      .container3ThisHover > * > & {
        color: colors.$base-primary-dark;
      }

      :focus-visible > * > &,
      :active > * > & {
        color: colors.$red-additional-dark;
      }
    }

    &:focus-visible, &:active {
      border-color: colors.$red-additional-dark;
    }
  }
}

.desc {
  overflow-wrap: anywhere;
}

@use 'sass:map';
@use 'sass:list';
@use 'src/styles/colors';
@import 'src/styles/bootstrapVars';
@import 'bootstrap/scss/mixins/breakpoints';

.base {
  font-style: normal;
  font-family: Inter, serif;
  font-optical-sizing: auto;
}

$headers: (
        H1: (size: 42px, weight: 600, height: 50px),
        H2: (size: 36px, weight: 600, height: 44px),
        H3: (size: 32px, weight: 600, height: 40px),
        H4: (size: 24px, weight: 600, height: 32px),
);

$types: (
        LargeTextSemibold: (size: 20px, weight: 600, height: 28px),
        NormalTextSemibold: (size: 16px, weight: 600, height: 24px),
        NormalTextRegular: (size: 16px, weight: 400, height: 24px),
        LinksHover: (size: 16px, weight: 400, height: 24px),
        SmallTextSemibold: (size: 14px, weight: 600, height: 22px),
        SmallTextRegular: (size: 14px, weight: 400, height: 22px),
        SmallLinksHover: (size: 14px, weight: 400, height: 22px),
        LabelSemibold: (size: 12px, weight: 600, height: 20px),
        LabelRegular: (size: 12px, weight: 400, height: 20px),
        Mobile_MenuLabel: (size: 10px, weight: 400, height: 18px),
        SmallLabel: (size: 10px, weight: 600, height: 18px),
);


@each $bpName, $bpValue in $grid-breakpoints {
  @include media-breakpoint-up($bpName) {
    .Inherit#{$bpName} {
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
    }

    @each $name, $value in $headers {
      .#{$name}Header#{$bpName} {
        font-size: map.get($value, 'size');
        font-weight: map.get($value, 'weight');
        line-height: map.get($value, 'height');
      }
    }

    @each $name, $value in $types {
      .#{$name}#{$bpName} {
        font-size: map.get($value, 'size');
        font-weight: map.get($value, 'weight');
        line-height: map.get($value, 'height');
      }
    }
  }
}


@each $name in colors.$colorsKeys {
  .#{$name}Color {
    color: var(--#{$name});
  }
};

.inheritColor {
  color: inherit;
}

.clickable {
  user-select: none;
}

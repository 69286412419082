@import 'src/styles/bootstrapVars';
@import 'bootstrap/scss/mixins/breakpoints';

@include media-breakpoint-up(md) {
  .content {
    max-height: 100dvh;
    width: 50.78%;
  }
}

@include media-breakpoint-up(lg) {
  .content {
    width: 36.11%;
  }
}

@include media-breakpoint-up(xl) {
  .content {
    width: 27.08%;
  }
}
